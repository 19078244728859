import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { ReactComponent as DriverIcon } from 'icons/driving-wheel-icon.svg';
import { Button, InputField, TableWrapper } from 'components/Global';
import SelectFieldDate from 'components/Global/SelectFieldDate';
import SelectFieldImage from 'components/Global/SelectFieldImage';
import { format, parseISO } from 'date-fns';
import { uploadFile } from 'features/orders/actions';
import { useAppContext } from 'components/Context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { createNewDriver, editDriver, getDetailDriver, switchDriverStatus } from 'features/drivers/actions';
import { checkEmptyObject, checkPermission } from 'utils/functionality';
import SelectFieldBanks from 'components/Global/SelectFieldBanks';
import { getAllBanks } from 'features/banks/actions';
import SelectFieldShuttle from 'components/Global/SelectFieldShuttle';
import { getAllRentalLocation } from 'features/rental-location/actions';

const UserStatus = ({ isUserBlocked, blockUserHandler }) => {
  return (
    <div className="status-info">
      <h3>{isUserBlocked ? 'Non Aktif' : 'Aktif'}</h3>
      <div className="status-info__toggle-wrapper">
        <div className="user__toggle-status">
          <label className="switch">
            <input type="checkbox" onChange={blockUserHandler} role={'switch'} checked={!isUserBlocked} />
            <span className="slider"></span>
          </label>
        </div>
      </div>
    </div>
  );
};

const AddNewDriver = () => {
  const { driverId } = useParams();
  const { showToast } = useAppContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState('');
  const [isUserBlocked, setIsUserBlocked] = useState(false);
  const [bankName, setBankName] = useState('');
  const [locationValue, setLocationValue] = useState({ id: 0, name: '' });
  const [data, setData] = useState({
    fullname: '',
    email: '',
    phone: '',
    date_of_birth: '',
    photo_ktp: '',
    photo_license: '',
    selfie: '',
    letter_of_agreement: '',
    bank_name: '',
    bank_account_number: '',
  });
  const driverData = useSelector((state) => state.drivers.selected);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);
  const { data: dataBanks } = useSelector((state) => state.banks);
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);
  const [searchParams] = useSearchParams();
  const isEdit = searchParams.get('is_edit');

  const backHandler = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    if (data.fullname.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Mengisi Nama' });
    } else if (data.email.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Mengisi Email' });
    } else if (data.phone.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Mengisi No. Handphone' });
    } else if (data.date_of_birth.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Mengisi Tanggal Lahir' });
    } else if (data.photo_ktp.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Memilih File KTP' });
    } else if (data.photo_license.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Memilih File SIM' });
    } else if (data.selfie.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Memilih File Selfie' });
    } else if (data.letter_of_agreement.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Memilih File Surat Perjanjian' });
    } else if (data.bank_account_number.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Mengisi Nomor Rekening' });
    } else if (data.bank_name.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Memilih Bank' });
    } else if (locationValue.name.trim() === '') {
      return showToast({ type: 'error', message: 'Harap Memilih Lokasi Driver' });
    }

    try {
      if (isEdit) {
        await dispatch(
          editDriver({
            payload: { ...data, location_id: locationValue.id, location_name: locationValue.name },
            id: driverId,
          }),
        ).unwrap();
        showToast({ type: 'success', message: 'Berhasil Merubah Data Akun Driver' });
      } else {
        await dispatch(
          createNewDriver({
            ...data,
            registration_type: 'email',
            location_id: locationValue.id,
            location_name: locationValue.name,
          }),
        ).unwrap();
        showToast({ type: 'success', message: 'Berhasil Membuat Akun Driver' });
      }
      navigate(-1);
    } catch (error) {
      if (isEdit) {
        showToast({ type: 'error', message: 'Gagal Merubah Data Akun Driver' });
        return;
      }
      showToast({ type: 'error', message: 'Gagal Membuat Akun Driver' });
    }
  };

  const onChangeImage = async (e, imageVar) => {
    if (!e.target.files.length) return;

    try {
      const uploadImage = await dispatch(uploadFile({ file: e.target.files[0], name: imageVar })).unwrap();
      setData((prev) => ({ ...prev, [imageVar]: uploadImage[imageVar] }));
    } catch (error) {
      showToast({ type: 'error', message: 'Gagal Melakukan Upload File' });
    }
  };

  const onRemoveImage = (imageVar) => {
    setData((prev) => ({ ...prev, [imageVar]: '' }));
  };

  const blockUserHandler = async () => {
    try {
      await dispatch(switchDriverStatus(driverId)).unwrap();
      showToast({ type: 'success', message: 'Berhasil Melakukan Aksi' });
      dispatch(getDetailDriver(driverId));
    } catch (error) {
      showToast({ type: 'error', message: 'Gagal Melakukan Aksi' });
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  useEffect(() => {
    if (!driverId) return;
    dispatch(getDetailDriver(driverId));
  }, [driverId]);

  useEffect(() => {
    dispatch(getAllBanks());
    dispatch(getAllRentalLocation());
  }, []);

  useEffect(() => {
    if (checkEmptyObject(driverData)) return;
    setData({
      fullname: driverData.name,
      email: driverData.email,
      phone: driverData.phone,
      date_of_birth: driverData.PersonalInfos?.date_of_birth,
      photo_ktp: driverData.PersonalInfos?.ktp,
      photo_license: driverData.PersonalInfos?.sim,
      selfie: driverData.PersonalInfos?.selfie,
      letter_of_agreement: driverData.PersonalInfos?.letter_of_statement,
      bank_account_number: driverData.account_bank?.no_rek,
      bank_name: driverData.account_bank?.nama_bank,
    });
    setSelectedDate(() =>
      driverData.PersonalInfos?.date_of_birth ? parseISO(driverData.PersonalInfos?.date_of_birth) : '',
    );
    setIsUserBlocked(() => driverData.is_deactivated);
    setLocationValue(() => ({
      id: driverData?.user_location?.location_id,
      name: driverData?.user_location?.location_name,
    }));
    setBankName(() => driverData.account_bank?.nama_bank);
  }, [driverData]);

  return (
    <div className="driver">
      <header className="driver__header" onClick={backHandler}>
        <LeftArrow />
        <p>Kembali</p>
      </header>
      <TableWrapper
        icon={<DriverIcon fill="#009EF7" width="25px" height="25px" />}
        title="Driver"
        CustomFilterComponent={
          driverId ? <UserStatus isUserBlocked={isUserBlocked} blockUserHandler={blockUserHandler} /> : null
        }
      >
        <div className="driver__wrapper">
          <h1 className="driver__title">{driverId ? (isEdit ? 'Edit Driver' : 'Detail Driver') : 'Tambah Driver'}</h1>
          <div className="driver__input-group">
            <InputField
              label="Nama"
              disable={driverId && !isEdit}
              value={data.fullname}
              onChange={(e) => setData((prev) => ({ ...prev, fullname: e.target.value }))}
              placeholder="Masukan Nama"
              className="input-field"
            />
            <InputField
              label="Email"
              disable={driverId && !isEdit}
              value={data.email}
              onChange={(e) => setData((prev) => ({ ...prev, email: e.target.value }))}
              placeholder="Masukan Email"
              className="input-field"
            />
            <InputField
              label="No. Handphone"
              disable={driverId && !isEdit}
              type="number"
              value={data.phone}
              onChange={(e) => setData((prev) => ({ ...prev, phone: e.target.value }))}
              placeholder="Masukan No. Handphone"
              className="input-field"
              onWheel={numberInputOnWheelPreventChange}
            />
            <SelectFieldDate
              label="Tanggal Lahir"
              disable={driverId && !isEdit}
              htmlFor="tanggal-lahir"
              name="tanggal-lahir"
              defaultMonth={selectedDate}
              handleDaySelect={(date) => {
                if (date) {
                  setSelectedDate(date);
                  setData((prev) => ({
                    ...prev,
                    date_of_birth: format(date, 'yyyy-MM-dd').toString(),
                  }));
                }
              }}
              value={selectedDate ? format(selectedDate, 'dd/MM/yyyy') : ''}
              selectedDay={selectedDate}
              placeholder="Pilih Tanggal"
              forDateOfBirth
            />
            <SelectFieldShuttle
              label="Lokasi"
              htmlFor="lokasi"
              value={locationValue.id}
              data={rentalLocationData}
              onChange={setLocationValue}
              disable={driverId && !isEdit}
              placeholder="Pilih Lokasi"
              searchFeature
            />
            <div />
            <SelectFieldImage
              label="KTP"
              disabled={driverId && !isEdit}
              value={data.photo_ktp}
              onChange={(e) => onChangeImage(e, 'photo_ktp')}
              onRemove={() => onRemoveImage('photo_ktp')}
            />
            <SelectFieldImage
              label="SIM"
              disabled={driverId && !isEdit}
              value={data.photo_license}
              onChange={(e) => onChangeImage(e, 'photo_license')}
              onRemove={() => onRemoveImage('photo_license')}
            />
            <SelectFieldImage
              label="Foto Selfie"
              disabled={driverId && !isEdit}
              value={data.selfie}
              onChange={(e) => onChangeImage(e, 'selfie')}
              onRemove={() => onRemoveImage('selfie')}
            />
            <SelectFieldImage
              label="Surat Perjanjian"
              disabled={driverId && !isEdit}
              value={data.letter_of_agreement}
              onChange={(e) => onChangeImage(e, 'letter_of_agreement')}
              onRemove={() => onRemoveImage('letter_of_agreement')}
            />
            <InputField
              label="Nomor Rekening"
              disable={driverId && !isEdit}
              type="number"
              value={data.bank_account_number}
              onChange={(e) => setData((prev) => ({ ...prev, bank_account_number: e.target.value }))}
              placeholder="Masukan Nomor Rekening"
              className="input-field"
              onWheel={numberInputOnWheelPreventChange}
            />
            <SelectFieldBanks
              htmlFor="metode-pembayaran-bank"
              label="Nama Bank"
              placeholder="Cari Bank..."
              data={dataBanks.data || []}
              value={bankName}
              disable={driverId && !isEdit}
              onChange={(e) => {
                setData((prev) => ({ ...prev, bank_name: e.target.value }));
                setBankName(e.target.value);
              }}
              onClick={setBankName}
            />
          </div>

          {!driverId || isEdit ? (
            <div className="driver__buttons">
              <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
                Kembali
              </Button>

              {(!driverId || (isEdit && checkPermission(offCanvasMenu, currentMenu, 'update'))) && (
                <Button size="sm" className="button" width={208} onClick={handleSubmit}>
                  Simpan
                </Button>
              )}
            </div>
          ) : null}
        </div>
      </TableWrapper>
    </div>
  );
};

export default AddNewDriver;
